import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Image from 'components/help/helpImage';
import Link from 'components/ui/link';

import logo from 'img/help/integrations/segment-logo.png';
import debuggerScreen from 'img/help/segment_debugger.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Segment?</SubTitle>
      <p>
        <strong>
          Segment is an analytics tool that aggregate all of the data about your customers in one
          place
        </strong>
        . Use this solution to minimize dependencies on your website and make tracking easier.
        We&apos;ve created a method that lets you send data from LiveSession to Segment.{' '}
      </p>
      <SubTitle className="mt-5">How does this integration work?</SubTitle>
      <p>
        This integration lets you add links to session replays from LiveSession to your Segment
        analytics as an event.{' '}
        <strong>
          As the result, you can transfer this data to other analytics tools and store it with rest
          of information about your users
        </strong>
        .
      </p>
      <p>
        Every time a new session will start, our code will send an event to Segment with a link to
        session replay.
      </p>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy>
        {`<script>
if(analytics && __ls){
__ls("getSessionURL", function(url, isNew){
    if(isNew){
        analytics.track('LiveSession Recording', {
            url: url
          });
    }
})
}
</script>`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To make this integration works you need to have Segment (analytics.js) and LiveSession
        installed on your website. If you haven&apos;t installed our tracking code yet check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
      <p>
        To see if you installed LiveSession integrations correctly on your website open your Segment
        debugger and find LiveSession Recording. It should contain link to recorded session.
      </p>
      <Image
        src={debuggerScreen}
        lazy
        alt="Segment integration"
        title="Correctly installed LiveSession"
      />
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Segment integration',
  canonical: '/help/segment-integration/',
  metaDescription: 'Send LiveSession recordings as events to Segment',
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
